import { postService, getService } from "../commonServices";

export class ProfileService {
  updateProfile(profile, id) {
    const body = JSON.stringify(profile)
    let url = `/admin/${id}`;
    let data = postService(url, body, 'PUT');
    return data;
  }

  /* Business info */
  getAdminBusinessInfo (id) {
    let url = `/admin/businessinfo/${id}`;
    let data = getService(url);
    return data;
  }
  
  updateBusinessInfo(body, id) {
    let url = `/admin/businessinfo/${id}`;
    let data = postService(url, body,  'PUT');
    return data;
  }
  
  createFileBusinessInfo(formData, id) {
    let url = `/admin/file/${id}?filetype=businessinfo`;
    let data = postService(url, formData);
    return data;
  }
  
  deleteFileBusinessInfo(body, id) {
    let url = `/admin/file/${id}?filetype=businessinfo`;
    let data = postService(url, body, 'DELETE');
    return data;
  }

  /* Bank */
  getAdminBank (id) {
    let url = `/admin/bank/${id}`;
    let data = getService(url);
    return data;
  }
  
  updateBank(body, id) {
    let url = `/admin/bank/${id}`;
    let data = postService(url, body,  'PUT');
    return data;
  }
  
  createFileBank(formData, id) {
    let url = `/admin/file/${id}?filetype=bank`;
    let data = postService(url, formData);
    return data;
  }
  
  deleteFileBank(body, id) {
    let url = `/admin/file/${id}?filetype=bank`;
    let data = postService(url, body, 'DELETE');
    return data;
  }
  
  /* Warehouse */
  getAdminWarehouse (id) {
    let url = `/admin/warehouse/${id}`;
    let data = getService(url);
    return data;
  }

  updateWarehouse(body, id) {
    let url = `/admin/warehouse/${id}`;
    let data = postService(url, body,  'PUT');
    return data;
  }

  createFileWarehouse(formData, id) {
    let url = `/admin/file/${id}?filetype=proofOfAddress`;
    let data = postService(url, formData);
    return data;
  }

  deleteFileWarehouse(body, id) {
    // let url = `/admin/file/${id}?filetype=bank`;
    // let data = postService(url, body, 'DELETE');
    // return data;
  }
  
}
