import { GET_PROFILE, UPDATE_BANK_TYPES, GET_BANK_INFO,UPDATE_BUSINESS_TYPES, GET_BUSINESS_INFO, FILES_TYPES, GET_WAREHOUSE_INFO, UPDATE_WAREHOUSE_TYPES } from "../types";


const initialState = {
    // profile : null,
    bank: null,
    warehouse: null,
    business: null,
    profileLoading: false
}

export default function (state = initialState, action) {
    const {type, payload} = action
    switch (type) {
        case GET_PROFILE:
            return {
                ...state,
                profile: payload,
            };
        case GET_BUSINESS_INFO:
            case UPDATE_BUSINESS_TYPES.UPDATE_BUSINESS:
                return {
                    ...state,
                    business: payload,
                };
        case GET_BANK_INFO:
        case UPDATE_BANK_TYPES.UPDATE_BANK:
            return {
                ...state,
                bank: payload,
            };
        case GET_WAREHOUSE_INFO:
        case UPDATE_WAREHOUSE_TYPES.UPDATE_WAREHOUSE:
            return {
                ...state,
                warehouse: payload,
            };
        case FILES_TYPES.GET_FILES:
            return {
                ...state,
                files: payload.files,
            };
        case FILES_TYPES.GET_FILE:
            return {
                ...state,
                file: payload,
            };
        default:
            return state;
    }
}
