import React, { useState } from "react";
import { Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha"

function SigninForm({ handleChange, handleSubmit, state, loading }) {
  const [value, setValue] = useState({ hidden: true });
  const [capVal, setCapVal] = useState(null);
  
  const toggleShow = () => {
    setValue({ hidden: !value.hidden });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}  autoComplete="on">
      <h1 className="text-center text-primary">Portal do Logista da Web Peças</h1>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          placeholder="email"
          name="email"
          autoComplete="on"
          onChange={handleChange}
          value={state.email}
          required
        />
      </div>

      <div className="form-group form-group-pass">
        <input
          type={value.hidden ? "password" : "text"}
          className="form-control"
          placeholder="password"
          autoComplete="on"
          name="password"
          onChange={handleChange}
          value={state.password}
          required
        />
        <div 
          className="illustration"
          onClick={toggleShow}
          style={{ cursor: "pointer", color: "#ffff" }}
        >
          {value.hidden ? (
            <i className="fa fa-eye-slash" />
          ) : (
            <i className="fa fa-eye" />
          )}
        </div>
      </div>

      <div className="form-group">
        <div className="re-captcha">
          <ReCAPTCHA 
              sitekey="6LfcqZ0qAAAAAFauEZBgQ6v3axzrxRNBGCzV7SWK"
              onChange={(val) => setCapVal(val)} 
          />
        </div>
        <Button
          className="btn btn-primary btn-block"
          htmlType="submit"
          loading={loading}
          disabled={!capVal}
        >
          Login
        </Button>
      </div>
    </form>
  );
}

export default SigninForm;
