import React from 'react'
import Notification from './Notification'

export default function index() {
  return (
    <>
      <Notification/>
    </>
  )
}
