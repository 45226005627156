import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAdminBank, getAdminBank } from '../../../redux/actions/profile_actions';
import { Form, Input, Button, Select, Row, Col, Tooltip, message } from 'antd';
import InputMask from 'react-input-mask';

const { Option } = Select;

const BankForm = ({ bank, updateAdminBank, getAdminBank, user }) => {
  const [form] = Form.useForm();
  const [isDisable, setIsDisabled] = useState(false);
  const [cpfCnpjType, setCpfCnpjType] = useState('CNPJ');

  useEffect(() => {
    if (user?._id) {
      getAdminBank(user._id);
    }
  }, [user, getAdminBank]);

  useEffect(() => {
    if (bank) {
      form.setFieldsValue({
        accountHolder: bank.accountHolder || '',
        cpfCnpj: bank.cpfCnpj || '',
        cpfCnpjType: bank.cpfCnpjType || 'cnpj',
        bankName: bank.bankName || '',
        branchName: bank.branchName || '',
        accountNumber: bank.accountNumber || '',
        accountType: bank.accountType || 'corrente',
        pixKey: bank.pixKey || '',
        pixKeyType: bank.pixKeyType || 'chaveAleatoria',
        phoneNumber: bank.phoneNumber || '',
        paymentPreference: bank.paymentPreference || 'pix',
      });
      setCpfCnpjType(bank.cpfCnpjType || 'CNPJ');
    }
  }, [bank, form]);

  const onCpfCnpjTypeChange = (value) => {
    setCpfCnpjType(value);
    form.setFieldsValue({ cpfCnpj: '' }); // Limpa o campo ao trocar entre CPF e CNPJ
  };

  const onSubmit = async (values) => {
    setIsDisabled(true);
    try {
      await updateAdminBank(values, user._id);
      message.success('Informações bancárias atualizadas com sucesso!');
    } catch (error) {
      message.error('Erro ao atualizar as informações bancárias.');
    }
    setIsDisabled(false);
  };

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Informações Bancárias</h5>
          <h6 className="card-subtitle text-muted">
            Preencha os campos com as informações corretas da sua conta bancária.
          </h6>
        </div>
        <div className="card-body">
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{
              accountHolder: '',
              cpfCnpj: '',
              cpfCnpjType: 'cnpj',
              bankName: '',
              branchName: '',
              accountNumber: '',
              accountType: 'corrente',
              pixKey: '',
              pixKeyType: 'chaveAleatoria',
              phoneNumber: '',
              paymentPreference: 'pix',
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="accountHolder"
                  label="Nome do Titular"
                  rules={[{ required: true, message: 'Insira o nome do titular!' }]}
                >
                  <Input placeholder="Nome completo do titular" />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Telefone de Contato"
                  rules={[
                    { required: true, message: 'Insira o telefone de contato!' },
                    { pattern: /^\(\d{2}\)\s\d{5}-\d{4}$/, message: 'Formato inválido! Use (XX) XXXXX-XXXX.' },
                  ]}
                >
                  <InputMask mask="(99) 99999-9999" placeholder="(XX) XXXXX-XXXX" className="ant-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="cpfCnpjType"
                  label="Tipo de Documento"
                  rules={[{ required: true, message: 'Selecione o tipo de documento!' }]}
                >
                  <Select onChange={onCpfCnpjTypeChange}>
                    <Option value="CPF">CPF</Option>
                    <Option value="CNPJ">CNPJ</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="cpfCnpj"
                  label={`Número do ${cpfCnpjType}`}
                  rules={[{ required: true, message: `Insira o ${cpfCnpjType}!` }]}
                >
                  <InputMask
                    mask={cpfCnpjType === 'CNPJ' ? '99.999.999/9999-99' : '999.999.999-99'}
                    placeholder={cpfCnpjType === 'CNPJ' ? '00.000.000/0000-00' : '000.000.000-00'}
                    className="ant-input"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="bankName"
                  label="Banco"
                  rules={[{ required: true, message: 'Insira o nome do banco!' }]}
                >
                  <Input placeholder="Nome do banco" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="branchName"
                  label={
                    <>
                        Agência 
                        <Tooltip title="Insira o número da agência, sem o dígito" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ADD8E6" width="18" height="18" style={{ marginLeft: '5px' }}>
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm1.07-7.75-.9.92C12.45 12.9 12.25 13.5 12.25 14h-2v-.5c0-1 .45-1.92 1.17-2.58l1.24-1.16c.37-.34.59-.83.59-1.34 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                            </svg>
                        </Tooltip>
                    </>
                  }
                  rules={[{ required: true, message: 'Insira o número da agência!' }]}
                >
                  <InputMask mask="9999" placeholder="0000" className="ant-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="accountNumber"
                  label={
                    <>
                        Conta 
                        <Tooltip title="Insira o número da conta, incluindo o dígito" >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ADD8E6" width="18" height="18" style={{ marginLeft: '5px' }}>
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm1.07-7.75-.9.92C12.45 12.9 12.25 13.5 12.25 14h-2v-.5c0-1 .45-1.92 1.17-2.58l1.24-1.16c.37-.34.59-.83.59-1.34 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                            </svg>
                        </Tooltip>
                    </>
                  }
                  rules={[{ required: true, message: 'Insira o número da conta!' }]}
                >
                  <InputMask mask="9999999-9" placeholder="0000000-0" className="ant-input" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="accountType"
                  label="Tipo de Conta"
                  rules={[{ required: true, message: 'Selecione o tipo de conta!' }]}
                >
                  <Select>
                    <Option value="corrente">Conta Corrente</Option>
                    <Option value="poupanca">Poupança</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pixKeyType"
                  label="Tipo de Chave PIX"
                  rules={[{ required: true, message: 'Selecione o tipo de chave PIX!' }]}
                >
                  <Select>
                    <Option value="celular">Celular</Option>
                    <Option value="cpf">CPF</Option>
                    <Option value="cnpj">CNPJ</Option>
                    <Option value="chaveAleatoria">Chave Aleatória</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="pixKey"
                  label="Chave PIX"
                  rules={[{ required: true, message: 'Insira a chave PIX!' }]}
                >
                  <Input placeholder="Chave PIX" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="paymentPreference"
                  label="Preferência de Pagamento"
                  rules={[{ required: true, message: 'Selecione a preferência de pagamento!' }]}
                >
                  <Select>
                    <Option value="pix">PIX</Option>
                    <Option value="deposito">Depósito em Conta</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" htmlType="submit" disabled={isDisable}>
              Enviar
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

BankForm.propTypes = {
  bank: PropTypes.object,
  updateAdminBank: PropTypes.func.isRequired,
  getAdminBank: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  bank: state.profile.bank,
});

export default connect(mapStateToProps, { updateAdminBank, getAdminBank })(BankForm);

// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { updateAdminBank, getAdminBank, createFileBank } from '../../../redux/actions/profile_actions';
// import { Modal, message, Form, Input, Button } from 'antd';

// const BankForm = ({ bank, updateAdminBank, getAdminBank, createFileBank, user }) => {
//     const [adminBank, setAdminBank] = useState({
//         accountHolder: '',
//         bankName: '',
//         branchName: '',
//         accountNumber: '',
//         routingNumber: '',
//         chequeCopy: null,
//     });
//     const [isDisable, setIsDisabled] = useState(false);
//     const [fileName, setFileName] = useState('');

//     useEffect(() => {
//         getAdminBank(user._id);
//     }, [user]);

//     useEffect(() => {
//         setAdminBank({
//             ...adminBank,
//             accountHolder: bank?.accountHolder || '',
//             bankName: bank?.bankName || '',
//             branchName: bank?.branchName || '',
//             accountNumber: bank?.accountNumber || '',
//             routingNumber: bank?.routingNumber || '',
//             chequeCopy: null,
//         });
//     }, [bank]);

//     const { accountHolder, bankName, branchName, accountNumber, routingNumber, chequeCopy } = adminBank;

//     const onChange = (e) =>
//         setAdminBank({
//             ...adminBank,
//             [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value,
//         });

//     const handleFileChange = (e) => {
//         const selectedFile = e.target.files[0];
//         //console.log('Selected File:', selectedFile); // Log file details
//         if(selectedFile !== undefined){
//             setFileName(selectedFile.name);
//             setAdminBank({
//                 ...adminBank,
//                 chequeCopy: selectedFile,
//             });
//         }else{
//             setFileName(null);
//             setAdminBank({
//                 ...adminBank,
//                 chequeCopy: null,
//             });
//         }
//     };

//     const onSubmit = async (e) => {
//         e.preventDefault();

//         if (!adminBank.chequeCopy) {
//             message.error('Insira a imagem do comprovante bancário!');
//             return;
//         }

//         const isJpgOrPng = adminBank.chequeCopy.type === 'image/jpeg' || adminBank.chequeCopy.type === 'image/png';
//         if (!isJpgOrPng) {
//             message.error('Você só pode enviar arquivos JPG/PNG!');
//             return;
//         }

//         setIsDisabled(true);
        
//         try {
//             const formData = new FormData();
//             formData.append('doc', adminBank.chequeCopy);
//             const result = await createFileBank(formData, user._id);
            
//             if(result !== undefined){
//                 adminBank.chequeCopy = result.data.bank._id
//                 const body = JSON.stringify(adminBank)
//                 await updateAdminBank(body, user._id);
//             }
            
//         } catch (error) {
//             message.error(error)
//         }
        
//         setIsDisabled(false);
//     };

//     return (
//         <div className="col-md-12">
//             <div className="card">
//                 <div className="card-header">
//                     <h5 className="card-title">Conta Bancária</h5>
//                 </div>
//                 <div className="card-body">
//                     <form onSubmit={onSubmit}>
//                         <div className="form-row">
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="accountHolder">Nome Completo</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="accountHolder"
//                                     placeholder="nome completo"
//                                     name="accountHolder"
//                                     value={accountHolder}
//                                     onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="routingNumber">CPF/CNPJ</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="routingNumber"
//                                     placeholder="CPF/CNPJ"
//                                     name="routingNumber"
//                                     value={routingNumber}
//                                     onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="bankName">Nome do banco</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="bankName"
//                                     placeholder="nome do banco"
//                                     name="bankName"
//                                     value={bankName}
//                                     onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="branchName">Número da Agência</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="branchName"
//                                     placeholder="número da agência"
//                                     name="branchName"
//                                     value={branchName}
//                                     onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="accountNumber">Número da Conta</label>
//                                 <input
//                                     type="text"
//                                     className="form-control"
//                                     id="accountNumber"
//                                     placeholder="número da conta"
//                                     name="accountNumber"
//                                     value={accountNumber}
//                                     onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="chequeCopy">Comprovante Bancário</label>
//                                 <input
//                                     type="file"
//                                     className="form-control-file"
//                                     id="chequeCopy"
//                                     name="chequeCopy"
//                                     onChange={handleFileChange}
//                                 />
//                             </div>
//                         </div>
//                         <button type="submit" className="btn btn-primary" disabled={isDisable}>
//                             Enviar
//                         </button>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     );
// };

// BankForm.propTypes = {
//     bank: PropTypes.object,
//     updateAdminBank: PropTypes.func.isRequired,
//     createFileBank: PropTypes.func.isRequired,
//     getAdminBank: PropTypes.func.isRequired,
// };

// const mapStateToProps = (state) => ({
//     bank: state.profile.bank,
// });

// export default connect(mapStateToProps, { updateAdminBank, getAdminBank, createFileBank })(BankForm);