import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Table as AntdTable, Input, Button, Space, Modal, Avatar, Drawer } from 'antd';
import Highlighter from 'react-highlight-words';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { beAdmin, getUsers } from '../../../../redux/actions/superadmin_action';
import AdminDetail from './AdminDetail';
import { filter } from 'lodash';

const Table = ({ multiLoading, admins, totalCount, getUsers }) => {
    let history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        total: 0,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    });

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const searchInput = useRef(null);

    useEffect(() => {
        getUsers(pagination.current, pagination.pageSize);
    }, [pagination.current, pagination.pageSize]);

    useEffect(() => {
        setPagination({ ...pagination, total: totalCount });
    }, [totalCount]);

    const handleTableChange = (pagination, filters) => {
        setPagination({ ...pagination, current: pagination.current, pageSize: pagination.pageSize });
        getUsers(pagination.current, pagination.pageSize, filters.status?.[0], filters.admin?.[0]);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const getUsersearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, backgroundColor: '#495057' }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Pesquisar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Cancelar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#495057' }} />,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: user => (
            <>
                {user.name}{' '}
                <Avatar shape="square" size="small" src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${user.photo}`} />
            </>
        )
    });

    const openUser = (user) => {
        //setIsDrawerOpen(true);
        //getAdmin(admin._id);
        alert("Em construção")
    };

    const sendEmail = (user) => {
        //let isAdmin = beAdmin(user._id, history);
        alert("Em construção")
    };

    const columns = useMemo(() => [
        {
            title: 'Usuário',
            dataIndex: '',
            key: 'admin',
            width: '20%',
            ...getUsersearchProps('')
        },
        {
            title: 'Origem',
            dataIndex: '',
            key: 'loginDomain',
            render: admin => `${admin.loginDomain}`,
            width: '10%',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            width: '10%',
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'status',
            filterMultiple: false,
            filters: [
                { text: 'All', value: 'undefined' },
                { text: 'Verified', value: 'verified' },
                { text: 'Not Verified', value: 'unverified' },
                { text: 'Blocked', value: 'blocked' },
            ],
            render: user => {
                if (user.isBlocked) return (<span className="badge badge-pill badge-danger">blocked</span>);
                else return (<span className="badge badge-pill badge-success">verified</span>);
            },
            width: '8%',
        },
        {
            title: 'Cadastro',
            dataIndex: 'createdAt',
            width: '10%',
            render: date => `${moment(date).format("DD/MM/YYYY")}`
        },
        {
            title: 'Action',
            dataIndex: '',
            width: '10%',
            render: user => (
                <>
                    <button onClick={e => openUser(user)} className="btn btn-info btn-sm"><i className="fas fa-eye"></i></button>
                    <button onClick={e => sendEmail(user)} className="btn btn-info btn-sm"><i className="fas fa-envelope"></i></button>
                </>
            ),
        },
    ], []);

    return (
        <>
            <AntdTable
                columns={columns}
                rowKey={record => record._id}
                dataSource={admins}
                pagination={pagination}
                loading={multiLoading}
                onChange={handleTableChange}
                size='small'
            />
            <Drawer
                title="Admin Details"
                placement="right"
                width={800}
                closable
                onClose={() => setIsDrawerOpen(false)}
                getContainer={false}
                open={isDrawerOpen}
                closeIcon={<i className="fas fa-times btn btn-danger"></i>}
            >
                <AdminDetail isAdminDetailOpen={isDrawerOpen} />
            </Drawer>
        </>
    );
};

Table.propTypes = {
    user: PropTypes.object,
    admins: PropTypes.array,
    multiLoading: PropTypes.bool,
    totalCount: PropTypes.number,
    //getAdmin: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    beAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    admins: state.superadmin.users,
    multiLoading: state.superadmin.loading,
    totalCount: state.superadmin.totalCount,
});

export default connect(mapStateToProps, { getUsers, beAdmin })(Table);
