import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Carousel, Tabs } from 'antd';
import { SideBySideMagnifier } from "react-image-magnifiers";
import parse from 'html-react-parser';
import ProductStatus from './ProductStatus'
import QuestionAnswer from './QuestionAnswer';

const { TabPane } = Tabs

export const ProductDetail = ({product, singleLoading, isSuperadmin}) => {
    const contentStyle = {
        // maxHeight: '300px',
        //height: '170px',
        color: '#fff',
        //lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    return (
        <div className="card column">
            <div className="card-header pt-3">
                <h2 className="text-center text-primary">{product?.name}</h2>
            </div>
            <div className='card-body p-4'>
                <div className="row">
                    <div className="col-md-6">
                        <Carousel>
                            {
                                product?.images.map(image => (

                                    <div key={image._id}>
                                        <h3 style={contentStyle}>
                                            <SideBySideMagnifier
                                                style={{ height: '100%', width: '100%', maxHeight:'300px' }}
                                                alwaysInPlace={true}
                                                imageSrc={`${process.env.REACT_APP_IMAGE_BASE_URL}/${image.medium}`}
                                                imageAlt="Example"
                                            // largeImageSrc={`${process.env.REACT_APP_IMAGE_BASE_URL}/${image.large}`} // Optional
                                            />
                                        </h3>
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-2">
                            <p><strong className="text-info">Marca:</strong> {product?.brand?.brandName}</p>
                            <p><strong className="text-info">categoria:</strong> {product?.category?.map(c=>c.displayName).join(', ')}</p>
                            <p><strong className="text-info">Slug:</strong> {product?.slug}</p>
                            <p><strong className="text-info">tags:</strong> {product?.tags?.join(', ')}</p>
                            <ProductStatus isSuperadmin={isSuperadmin} product={product}/>
                        </div>
                    </div>
                </div>
                <div className="row mb-12">
                    <div className="col-md-12">
                        <hr className="my-4"/>
                        <Tabs >
                            <TabPane tab="Descrição" key="a">
                                {product && parse(product?.description)}
                            </TabPane>
                            <TabPane tab="Destaques" key="b">
                                {product && parse(product?.highlights)}
                            </TabPane>
                            <TabPane tab="Informações" key="c">
                            <div className="row">
                                <div className="col-xl-6">
                                    <p><strong className="text-info">Marca:</strong> {product?.brand.brandName}</p>
                                    <p><strong className="text-info">Modelo:</strong> {product?.model}</p>
                                    <p><strong className="text-info">Preço:</strong> R$ {parseFloat(product?.price.$numberDecimal).toFixed(2)}</p>
                                    <p><strong className="text-info">Quantidade:</strong> {product?.quantity}</p>
                                    <p><strong className="text-info">Garantia:</strong> {product?.warranty}</p>
                                    <p><strong className="text-info">Retorno:</strong> {product?.return}</p>
                                </div>
                                <div className="col-xl-6">
                                    <p><strong className="text-info">Desconto:</strong> {product?.discountRate}%</p>
                                    <p><strong className="text-info">Tags:</strong> {product?.tags.join(", ")}</p>
                                    <p><strong className="text-info">Dimensões:</strong> {product?.size.join(", ")}</p>
                                    <p><strong className="text-info">Cor:</strong> {product?.color.join(", ")}</p>
                                    <p><strong className="text-info">Peso:</strong> {product?.weight.join(", ")}</p>
                                    <p><strong className="text-info">Distritos Disponíveis:</strong> {product?.availableDistricts.join(", ")}</p>
                                </div>
                            </div>
                            </TabPane>
                            <TabPane tab="Video" key="d">
                                <p><strong className="text-info">Vídeo:</strong></p>
                                <iframe
                                    width="100%"
                                    height="315"
                                    src={`https://www.youtube.com/embed/${product?.videoURL[0]}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="Product Video"
                                ></iframe>
                            </TabPane> 
                            <TabPane tab="Armazém" key="e">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <p><strong className="text-info">Proprietário:</strong> {product?.soldBy?.adminWareHouse.ownerName}</p>
                                        <p><strong className="text-info">Rua/Avenida:</strong> {product?.soldBy?.adminWareHouse.address}</p> 
                                        <p><strong className="text-info">Bairro:</strong> {product?.soldBy?.adminWareHouse.area}</p>
                                        <p><strong className="text-info">Estado:</strong> {product?.soldBy?.adminWareHouse.region}</p>
                                        <p><strong className="text-info">Complemento:</strong> {product?.soldBy?.adminWareHouse.complement}</p>
                                    </div>
                                    <div className="col-xl-6">
                                        <p><strong className="text-info">Telefone:</strong> {product?.soldBy?.adminWareHouse.phoneNumber}</p>
                                        <p><strong className="text-info">Número:</strong> {product?.soldBy?.adminWareHouse.number}</p>
                                        <p><strong className="text-info">Cidade:</strong> {product?.soldBy?.adminWareHouse.city}</p>
                                        <p><strong className="text-info">Cep:</strong> {product?.soldBy?.adminWareHouse.cep}</p> 
                                    </div>
                                </div> 
                            </TabPane>
                           <TabPane tab="Dúvidas" key="f">
                                <QuestionAnswer productSlug={product?.slug}/>
                            </TabPane> 
                            <TabPane tab="Estatística" key="g">
                                <p><strong className="text-info">Vendas:</strong> {product?.noOfSoldOut}</p>
                                <p><strong className="text-info">Pontuação de Tendência:</strong> {parseFloat(product?.trendingScore.$numberDecimal).toFixed(2)}</p>
                                <p><strong className="text-info">Visualizações:</strong> {product?.viewsCount}</p>
                            </TabPane> 
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

ProductDetail.propTypes = {
    product: PropTypes.object,
    singleLoading: PropTypes.bool,
    isSuperadmin:PropTypes.bool,
}

const mapStateToProps = (state) => ({
    product: state.product.product,
    singleLoading: state.product.singleLoading
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
