import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, Button, DatePicker, Select, Row, Col } from 'antd';
import InputMask from 'react-input-mask';
import { updateAdminBusinessInfo, getAdminBusinessInfo } from '../../../redux/actions/profile_actions';
import moment from 'moment';

const { Option } = Select;

const BusinessForm = ({ business, user, updateAdminBusinessInfo, getAdminBusinessInfo, loading }) => {
    const [form] = Form.useForm();
    const [onHoliday, setOnHoliday] = useState(false);

    useEffect(() => {
        if (user?._id) {
            getAdminBusinessInfo(user._id);
        }
    }, [user, getAdminBusinessInfo]);

    useEffect(() => {
        if (business) {
            form.setFieldsValue({
                fantasyName: business.fantasyName || '',
                foundationDate: business.foundationDate ? moment(business.foundationDate) : null,
                cnpj: business.cnpj || '',
                responsible: business.responsible || '',
                contact: business.contact || '',
                onHoliday: business.onHoliday || false,
                holidayStart: business.holidayStart ? moment(business.holidayStart) : null,
                holidayEnd: business.holidayEnd ? moment(business.holidayEnd) : null,
                description: business.description || '',
            });
            setOnHoliday(business.onHoliday || false);
        }
    }, [business, form]);

    const onFinish = (values) => {
        updateAdminBusinessInfo(values, user._id);
    };

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Informações do Negócio</h5>
                </div>
                <div className="card-body">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onFinish}
                        initialValues={{ onHoliday: false }}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Nome Fantasia"
                                    name="fantasyName"
                                    rules={[{ required: true, message: 'O Nome Fantasia é obrigatório.' }]}
                                >
                                    <Input placeholder="Nome fantasia" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Data de Fundação"
                                    name="foundationDate"
                                    rules={[{ required: true, message: 'A Data de Fundação é obrigatória.' }]}
                                >
                                <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                            </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="CNPJ"
                                    name="cnpj"
                                    rules={[{ required: true, message: 'O CNPJ é obrigatório.' }]}
                                >
                                    <InputMask
                                    mask="99.999.999/9999-99"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        form.setFieldsValue({ cnpj: value });
                                    }}
                                    >
                                    {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Responsável"
                                    name="responsible"
                                    rules={[{ required: true, message: 'O Responsável é obrigatório.' }]}
                                >
                                    <Input placeholder="Responsável" />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Telefone de Contato"
                                    name="contact"
                                    rules={[{ required: true, message: 'O Telefone de Contato é obrigatório.' }]}
                                >
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            form.setFieldsValue({ contact: value });
                                        }}
                                        >
                                        {(inputProps) => <Input {...inputProps} />}
                                    </InputMask>
                                </Form.Item>
                            </Col>  
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                        label="Está de Férias?"
                                        name="onHoliday"
                                    >
                                    <Select 
                                        onChange={(value) => setOnHoliday(value === 'true')}
                                        placeholder="Selecione se está de férias"
                                    >
                                        <Option value="" disabled>{onHoliday ? 'Não' : 'Sim'}</Option>
                                        <Option value="false">Não</Option>
                                        <Option value="true">Sim</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            {onHoliday && (
                                <>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Início das Férias"
                                            name="holidayStart"
                                            rules={[{ required: true, message: 'A data de início das férias é obrigatória.' }]}
                                        >
                                            <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Fim das Férias"
                                            name="holidayEnd"
                                            rules={[{ required: true, message: 'A data de fim das férias é obrigatória.' }]}
                                        >
                                            <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Descrição do Negócio"
                                    name="description"
                                >
                                    <Input.TextArea placeholder="Descrição detalhada do negócio" rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Enviar
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
};

BusinessForm.propTypes = {
    business:  PropTypes.object,
    user: PropTypes.object.isRequired,
    updateAdminBusinessInfo: PropTypes.func.isRequired,
    getAdminBusinessInfo: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    user: state.auth.adminProfile,
    business: state.profile.business,
});

export default connect(mapStateToProps, { updateAdminBusinessInfo, getAdminBusinessInfo })(React.memo(BusinessForm));

// import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types'
// import { connect } from 'react-redux'
// import { updateAdminBusinessInfo } from '../../../redux/actions/profile_actions'


// const BusinessForm = ({user, updateAdminBusinessInfo, loading}) => {
//     const [profile, setProfile] = useState({
//         name:'',
//         dist:'',
//         address:'',
//         cpf:'',
//         cnpj:'',
//         identityFront:'',
//         identityBack:'',
//         license:'',
//         holidayEnd:'',
//     });
//     // useEffect(()=>{
//     //     user && setProfile({
//     //         ...profile,
//     //         name:user?.name?user.name:'',
//     //         dist:user?.dist?user.dist:'',
//     //         address:user?.address?user.address:'',
//     //         cpf:user?.cpf?user.cpf:'',
//     //         cnpj:user?.cnpj?user.cnpj:'',
//     //         identityFront:user?.identityFront?user.identityFront:'',
//     //         identityBack:user?.identityBack?user.identityBack:'',
//     //     })
//     // },[user])
//    const {name,address,dist,cpf,cnpj,identityBack,identityFront,license} = profile
    
//     const onChange = e => setProfile({...profile,[e.target.name]:e.target.value})
//     const onSubmit = async e => {
//         e.preventDefault()
//         //updateProfile(profile,user._id)
        
//     }
//     return (
//         <div className="col-md-12">
//             <div className="card">
//                 <div className="card-header">
//                     <h5 className="card-title">Informação de negócios</h5>
//                 </div>
//                 <div className="card-body">
//                     <form onSubmit={e=> onSubmit(e)}>
//                         <div className="form-row">
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="inputEmail4">Nome do proprietário</label>
//                                 <input type="text" className="form-control" id="inputEmail4" placeholder="Nome do proprietário" 
//                                 name='name'
//                                 value={name}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="add">Endereço</label>
//                                 <input type="text" className="form-control" id="add" placeholder="Endereço" 
//                                 name='address'
//                                 value={address}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="dist">Estado</label>
//                                 <input type="text" className="form-control" id="dist" placeholder="Estado" 
//                                 name='dist'
//                                 value={dist}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="cpf">CPF</label>
//                                 <input type="text" className="form-control" id="cpf" placeholder="CPF" 
//                                 name='cpf'
//                                 value={cpf}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="cnpj">CNPJ</label>
//                                 <input type="text" className="form-control" id="cnpj" placeholder="CNPJ" 
//                                 name='cnpj'
//                                 value={cnpj}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             {/* <div className="form-group col-md-6">
//                                 <label htmlFor="exampleFormControlFile1">Imagem da frente da identidade</label>
//                                 <input type="file" className="form-control-file" id="exampleFormControlFile1"
//                                 name='identityFront'
//                                 value={identityFront}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="exampleFormControlFile2">Imagem traseira da identidade</label>
//                                 <input type="file" className="form-control-file" id="exampleFormControlFile2" 
//                                 name='identityBack'
//                                 value={identityBack}
//                                 onChange={onChange}
//                                 />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="exampleFormControlFile3">Cópia da licença comercial</label>
//                                 <input type="file" className="form-control-file" id="exampleFormControlFile3" 
//                                 name='license'
//                                 value={license}
//                                 onChange={onChange}
//                                 />
//                             </div> */}
//                         </div>
//                         <button type="submit" className="btn btn-primary">Enviar</button>
//                     </form>
//                 </div>
//             </div>
//         </div>

//     )
// }

// // BusinessForm.propTypes = {

// // }

// //  export default BusinessForm

// BusinessForm.propTypes = {
//     BusinessInfo: PropTypes.object,
//     updateAdminBusinessInfo: PropTypes.func.isRequired,
//     loading: PropTypes.bool,
// }
// const mapStateToProps = (state) => ({
//     loading: state.auth.loading,
//     user: state.auth.adminProfile,
// })


// export default connect(mapStateToProps,{updateAdminBusinessInfo})(React.memo(BusinessForm))
