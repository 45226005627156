import React from 'react'
import Layout from '../../core/Layout'

export default function index() {
  return (
    <Layout>
      Em construção!!
    </Layout>
  )
}
