import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Input, Space, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { getQandA, postAnswer, deleteQuestion } from '../../../redux/actions/product_actions';
import moment from 'moment';

const { TextArea } = Input;

function QuestionAnswer({ getQandA, postAnswer, deleteQuestion, productQA, productSlug, user }) {
  const [QnA, setQnA] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [editingAnswer, setEditingAnswer] = useState({});

  useEffect(() => {
    if (productQA?.QnA) {
      setQnA(productQA.QnA.qna || []);
      setTotalCount(productQA.totalCount || 0);
      setLoading(false);
    }
  }, [productQA]);

  useEffect(() => {
    getQandA(`${productSlug}?page=${currentPage}&perPage=5`);
  }, [getQandA, currentPage, productSlug]);

  const handleAnswerChange = (id, value) => {
    setEditingAnswer((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmitAnswer = (id) => {
    const answer = editingAnswer[id];
    if (!answer) {
      message.error('Por favor, insira uma resposta.');
      return;
    }

    const body = {
      qna_id: id,
      answer: answer
    };

    postAnswer(`${user._id}/${productSlug}`, body);

    setEditingAnswer((prev) => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
    message.success('Resposta enviada com sucesso!');
  };

  const handleDeleteQuestion = (id) => {
    const body = {
      qna_id: id,
    };

    deleteQuestion(user._id, productSlug, body);
    message.success('Pergunta deletada com sucesso!');
  };

  // Formatação da data com Moment.js para o formato brasileiro (DD/MM/YYYY)
  const formatDate = (date) => {
    return moment(date).locale('pt-br').format('DD/MM/YYYY HH:mm');
  };

  const columns = [
    {
      title: 'Cliente',
      dataIndex: ['questionby', 'name'],
      key: 'customer',
      render: (name, record) => (
        <div>
          <strong>{name}</strong>
        </div>
      ),
    },
    {
      title: 'Pergunta',
      dataIndex: 'question',
      key: 'question',
      render: (name, record) => (
        <>
          <strong>{record.question}</strong>
          <br />
          <span style={{ fontSize: '12px', color: '#888' }}>
            {formatDate(record.questionedDate)}
          </span>
        </>
      ),
    },
    {
      title: 'Resposta',
      key: 'answer',
      render: (name, record) => (
        <>
          {console.log(record)}
          {!record.answer && 
            <Space direction="vertical" style={{ width: '100%' }}>
              <TextArea
                rows={2}
                value={editingAnswer[record._id] || ''}
                onChange={(e) => handleAnswerChange(record._id, e.target.value)}
                placeholder="Digite sua resposta aqui"
              />
              <Button type="primary" onClick={() => handleSubmitAnswer(record._id)}>
                Responder
              </Button>
            </Space>
          }
          {record.answer &&
          <>
           <strong>{record.answer}</strong>
            <br />
            <span style={{ fontSize: '12px', color: '#888' }}>
              {formatDate(record.answeredDate)}
            </span>
          </>
          } 
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'actions',
      render: (_, record) => (
        <Popconfirm
          title="Tem certeza que deseja deletar esta pergunta?"
          onConfirm={() => handleDeleteQuestion(record._id)}
          okText="Sim"
          cancelText="Não"
        >
          <Button danger>Deletar</Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div style={{ padding: '10px' }}>
      <Table
        rowKey="_id"
        dataSource={QnA}
        columns={columns}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: 5,
          total: totalCount,
          onChange: (page) => setCurrentPage(page),
        }}
      />
    </div>
  );
}

QuestionAnswer.propTypes = {
  user: PropTypes.object,
  getQandA: PropTypes.func.isRequired,
  postAnswer: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired,
  productQA: PropTypes.object,
};

const mapStateToProps = (state) => ({
  productQA: state.product.productQA,
  user: state.auth.adminProfile,
});

export default connect(mapStateToProps, { getQandA, postAnswer, deleteQuestion })(QuestionAnswer);