import React from 'react';
import { Row, Col, Card, Progress } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Todo = ({ user, completedPercent }) => {
  const getIcon = (isComplete) => {
    return isComplete ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#f44455" />;
  };

  return (
    <div className="col-xl-12">
      <div className="card flex-fill w-100">
        <div className="card-header">
          <h5 className="card-title mb-2">Oi, complete as tarefas para iniciar sua jornada de vendas!</h5>
          <Progress
              className="card-title mb-0"
              format={percent => `${percent}% done`}
              style={{ width: 200 }}
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              trailColor='#354052'
              percent={completedPercent}
              status="active"
            />
        </div>
        <div className="card-body p-2">
          <div className="all-cards">
            <Row>
              <Col lg={6}>
                <Link to={!user?.shopName ? '/profile' : ''}>
                  <Card
                    title="Perfil"
                    extra={getIcon(user.shopName)}
                    hoverable={!user?.shopName}
                    className="todoCard"
                  >
                    <p>Complete seu perfil</p>
                  </Card>
                </Link>
              </Col>
              <Col lg={6}>
                <Link to={!user?.businessInfo ? '/profile' : ''}>
                  <Card
                    title="Informações da Loja"
                    className="todoCard"
                    extra={getIcon(user.businessInfo)}
                    hoverable={user?.businessInfo}
                  >
                    <p>Verifique as informações da sua empresa</p>
                  </Card>
                </Link>
              </Col>
              <Col lg={6}>
                <Link to={!user?.adminBank ? '/profile' : ''}>
                  <Card
                    title="Detalhes bancários"
                    className="todoCard"
                    extra={getIcon(!!user.adminBank)}
                    hoverable={!user?.adminBank}
                  >
                    <p>Para receber seu dinheiro</p>
                  </Card>
                </Link>
              </Col>
              <Col lg={6}>
                <Link to={!user?.adminWareHouse ? '/profile' : ''}>
                  <Card
                    title="Endereço de devolução"
                    className="todoCard"
                    extra={getIcon(user.adminWareHouse)}
                    hoverable={!user?.adminWareHouse}
                  >
                    <p>Endereço de origem/devolução do seu produto</p>
                  </Card>
                </Link>
              </Col>
            </Row>
            <div className="dashed-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

Todo.propTypes = {};

export default Todo;
