import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Radio, Popconfirm, Checkbox, Button } from 'antd';
import { approveProduct, disApproveProduct, toggleProductFeatured } from '../../../redux/actions/superadmin_action';

export const ProductStatus = ({ isSuperadmin, product, loading, approveProduct, disApproveProduct, toggleProductFeatured }) => {
    const [value, setValue] = useState(null);
    const [openRejectForm, setOpenRejectForm] = useState(false);
    const [rejectFormData, setRejectFormData] = useState('');
    const [openFeaturedConfirmation, setOpenFeaturedConfirmation] = useState(false);
    const [openVerifyConfirmation, setOpenVerifyConfirmation] = useState(false);
    const [isFeatured, setIsFeatured] = useState(product ? product.isFeatured : false);

    useEffect(() => {
        setValue(null); // Reset the radio button selection when the product status changes
        if (product) {
            setIsFeatured(product.isFeatured); // Update the local state when the product prop changes
        }
    }, [product]);

    const onChange = (e) => {
        if (e.target.value === 'verify') {
            setOpenVerifyConfirmation(true); // Open the Popconfirm for verification confirmation
        } else if (e.target.value === 'reject') {
            setOpenRejectForm(true); // Open the Popconfirm for reject form
        }
        setValue(e.target.value);
    }

    const onFeaturedCheckboxClicked = () => {
        toggleProductFeatured(product.slug).then(() => {
            setIsFeatured(!isFeatured); // Toggle the local state for isFeatured
        });
        setOpenFeaturedConfirmation(false); // Close the Popconfirm after confirming the action
    }

    const handleRejectFormData = (e) => {
        setRejectFormData(e.target.value);
    }

    const makeProductFeatured = () => {
        onFeaturedCheckboxClicked();
    }

    const rejectProduct = () => {
        if (rejectFormData) {
            disApproveProduct(product.slug, rejectFormData);
            setOpenRejectForm(false); // Close the Popconfirm after confirming the action
        }
    }

    const verifyProduct = () => {
        approveProduct(product.slug);
        setOpenVerifyConfirmation(false); // Close the Popconfirm after confirming the action
    }

    // Define the rejectForm function here
    const rejectForm = (
        <div className="col-md-12">
            Observação:
            <textarea className="form-control" onChange={handleRejectFormData} name="comment" rows={2} placeholder="Motivo da rejeição" value={rejectFormData} required />
        </div>
    );

    return (
        <Fragment>
            <div className="mb-2">
                <p><strong  className="text-info">Status:</strong> {product && (
                    product.isDeleted && isSuperadmin ? <><span className="badge badge-pill badge-dark">Excluído</span></> :
                    product.isRejected ? <><span className="badge badge-pill badge-warning">Rejeitado</span></> :
                    isFeatured ? <><span className="badge badge-pill badge-secondary">Destaque</span><span className="badge badge-pill badge-success">Verificado</span></> :
                    !product.isVerified ? <span className="badge badge-pill badge-danger">Não verificado</span> :
                    product.isVerified ? <span className="badge badge-pill badge-success">Verificado</span> :
                    null
                )}
                </p>

                {
                    isSuperadmin && product && !product.isDeleted && !product.isRejected && !product.isVerified && !isFeatured &&
                    <Popconfirm
                        title={rejectForm}
                        visible={openRejectForm}
                        onConfirm={rejectProduct}
                        onCancel={() => setOpenRejectForm(false)}
                        okButtonProps={{ loading }}
                        okText="Submit"
                        cancelText="Cancel"
                    >
                        <Radio.Group onChange={onChange} value={value}>
                            <Radio disabled={loading} value={'verify'}>Verificar</Radio>
                            <Radio disabled={loading} value={'reject'}>Rejeitar</Radio>
                        </Radio.Group>
                    </Popconfirm>
                }
                {
                    isSuperadmin && product && !product.isDeleted && !product.isRejected && !product.isVerified &&
                    <Popconfirm
                        title={"Tem certeza de verificar este produto?"}
                        visible={openVerifyConfirmation}
                        onConfirm={verifyProduct}
                        onCancel={() => setOpenVerifyConfirmation(false)}
                        okButtonProps={{ loading }}
                        okText="Sim"
                        cancelText="Cancelar"
                    >
                        {/* <Button type="primary" onClick={() => setOpenVerifyConfirmation(true)}>Verificar</Button> }*/}
                    </Popconfirm>
                }
                {
                    isSuperadmin && product && !product.isDeleted && !product.isRejected && product.isVerified &&
                    <Popconfirm
                        title={"Tem certeza que deseja destacar esse produto?"}
                        visible={openFeaturedConfirmation}
                        onConfirm={makeProductFeatured}
                        onCancel={() => setOpenFeaturedConfirmation(false)}
                        okButtonProps={{ loading }}
                        okText="Sim"
                        cancelText="Cancelar"
                    >
                        <Checkbox
                            disabled={loading}
                            onChange={() => setOpenFeaturedConfirmation(true)}
                            checked={isFeatured}
                        >
                            Destacar
                        </Checkbox>
                    </Popconfirm>
                }
            </div>
        </Fragment>
    )
}

ProductStatus.propTypes = {
    product: PropTypes.object,
    isSuperadmin: PropTypes.bool,
    loading: PropTypes.bool,
    approveProduct: PropTypes.func.isRequired,
    disApproveProduct: PropTypes.func.isRequired,
    toggleProductFeatured: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    loading: state.product.toggleProductApprovalLoading
})

const mapDispatchToProps = {
    approveProduct,
    disApproveProduct,
    toggleProductFeatured
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStatus);