import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { signOut } from '../../../redux/actions/auth_actions'
import { Link } from 'react-router-dom';
import NotificationBar from './NotificationBar';
import NotificationBarSystem from './NotificationBarSystem';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

const TopNavbar = ({ signOut,user, collapsed, toggleSidebar }) => {
  return (
		<nav className="navbar navbar-top navbar-expand navbar-light bg-white sticky-top">
			<div className="sidebar-icon d-flex mr-2 d-flex" onClick={toggleSidebar}>
				{React.createElement(collapsed ? MenuFoldOutlined : MenuUnfoldOutlined, {})}
			</div> 

			<div className="navbar-collapse collapse">
				<ul className="navbar-nav ml-auto">
					<NotificationBarSystem/>
					<NotificationBar/>
					
					<li className="nav-item dropdown">
						<Link className="nav-icon dropdown-toggle d-inline-block d-sm-none" to='' data-toggle="dropdown">
							<i className="align-middle" data-feather="settings"></i>
						</Link>

						<Link className="nav-link dropdown-toggle d-none d-sm-inline-block" to='' data-toggle="dropdown">
							<img src="/img/avatar1.png" className="avatar img-fluid rounded-circle mr-1" alt="user"/> 
							<span className="text-dark">{user && user.name}</span>
						</Link>
						<div className="dropdown-menu dropdown-menu-right">
							<Link className="dropdown-item" to='/profile'><i className="align-middle mr-1" data-feather="user"></i>Perfil</Link>
							<Link className="dropdown-item" to='/'><i className="align-middle mr-1" data-feather="pie-chart"></i>Análise</Link>
							<div className="dropdown-divider"></div>
							<Link className="dropdown-item" to='/settings-privacy'>Configurações e privacidade</Link>
							<Link className="dropdown-item" to='/help'>Ajuda</Link>
							<Link className="dropdown-item" to='' onClick={signOut}>Sair</Link>
						</div>
					</li>
				</ul>
			</div>
		</nav>
  )
}
TopNavbar.propTypes = {
	signOut: PropTypes.func.isRequired,
	user: PropTypes.object,
	collapsed: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
}
const mapStateToProps = state =>({
	user:state.auth.authUser,
})
export default connect(mapStateToProps, { signOut })(React.memo(TopNavbar))
