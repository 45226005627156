import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {Switch,Popconfirm, Tooltip, Select} from 'antd'
import { connect } from 'react-redux'
import { toggleOrderApproval, toggletobeReturnOrder, toggletobeDispatchOrder, toggletobeComplete } from '../../../redux/actions/order_actions'
const { Option } = Select;

const OrderStatus = ({loading, status, order_id, admin_id, toggleOrderApproval, toggletobeReturnOrder, toggletobeComplete, toggletobeDispatchOrder, remainingProductQty, isOrderDetailOpen}) => {
    const [defaultCheckApprove, setdefaultCheckApprove] = useState(false)
    const [defaultCheckComplete, setdefaultCheckComplete] = useState(false)
    // const [loading, setLoading] = useState(false)

    const [switchClass, setSwitchClass] = useState('')
    const [openToBeCompleteForm, setOpenToBeCompleteForm] = useState(false)
    const [openToBeReturnedForm, setOpenToBeReturnedForm] = useState(false)
    const [toBereturnedFormData, setToBeReturnedFormData] = useState({
        remark:'',
        returnedAmount: ''
    })
    const [openToBeDispatchForm, setOpenToBeDispatchForm] = useState(false)
    const [toBeDispatchFormData, setToBeDispatchFormData] = useState({
        tracking:'',
    })

    useEffect(()=>{
        if (status === 'active') {
            setSwitchClass('order-status-active')
            setdefaultCheckApprove(true)
            
        }
        if (status === 'approve') {
            setSwitchClass('order-status-approve')
            setdefaultCheckApprove(false)
        }
        if (status === 'complete') {
            setSwitchClass('order-status-complete')
            setdefaultCheckComplete(true)
        }
        if (status === 'tobereturned') {
            setSwitchClass('order-status-tobereturned')
            setdefaultCheckComplete(false)
        }
        if (status === 'return') {
            setSwitchClass('order-status-complete')
            setdefaultCheckComplete(true)
        }

        // setLoading(false)
        setOpenToBeReturnedForm(false)
        setToBeReturnedFormData({...toBeReturnedForm,remark:'',returnedAmount:''})

    },[status])

    useEffect(() => {
        !isOrderDetailOpen && setOpenToBeReturnedForm(false)
        !isOrderDetailOpen && setToBeReturnedFormData({ ...toBeReturnedForm, remark: '', returnedAmount: '' })
     }, [isOrderDetailOpen])

    //active/approve
    const toggleApproval = () => {
        // setLoading(true)
        toggleOrderApproval(admin_id, order_id)
    }

    const switchToToBeReturned = () => {
        //validata form data
        //set loading 
        // setLoading(true)
        //call action creator with remark and returned amount
        toggletobeReturnOrder(admin_id, order_id, toBereturnedFormData.remark, toBereturnedFormData.returnedAmount)
    }
    
    const switchToToBeDispatch = () => {
        //validata form data
        //set loading 
        // setLoading(true)
        //call action creator with remark and returned amount
        toggletobeDispatchOrder(admin_id, order_id, toBeDispatchFormData.tracking)
    }

    const switchToComplete = () => {
        // setLoading(true)
        toggletobeComplete(admin_id, order_id)
    }
    
    const handleReturnedFormDataChange = e => {
        setToBeReturnedFormData({
            ...toBereturnedFormData,
            [e.target.name]: e.target.value
        });
    };

    //to be returned form 
    const toBeReturnedForm = () => {
        return (
          <div className="col-md-12">
            <label htmlFor="predefinedReason" style={{ display: "block", marginBottom: "8px" }}>
              Motivo da devolução:
            </label>
            <select
              id="predefinedReason"
              name="remark"
              className="form-select" // Classe do Bootstrap
              style={{ marginBottom: "10px" }}
              onChange={(e) => {
                handleReturnedFormDataChange({ target: { name: "remark", value: e.target.value } });
              }}
            >
              <option value="Endereço não encontrado">Endereço não encontrado</option>
              <option value="Comprador recusou a entrega">Comprador recusou a entrega</option>
              <option value="Produto danificado">Produto danificado</option>
              <option value="Pedido incorreto">Pedido incorreto</option>
              <option value="Comprador desistiu da compra">Comprador desistiu da compra</option>
              <option value="Outros">Outro</option>
            </select> 
          </div>
        );
    };

    const handleDispatchFormDataChange = e => {
        setToBeDispatchFormData({
            ...toBeDispatchFormData,
            [e.target.name]: e.target.value
        });
    };
    //to be Dispatch form 
    const toBeDispatchForm = () =>{
        return (
            <div className="col-md-12">
                Código de Rastreio:
                <textarea className="form-control" onChange={handleDispatchFormDataChange} name="tracking" rows={2} placeholder="Código de rastreio" value={toBeDispatchFormData.remark} required />
            </div>
        )
    }

    //rendering..
    //for status active is qty is out of stock we have to disable toggle feature so we need switch
    if (status === 'paid'&& remainingProductQty < 1) return <Tooltip title="Product is out of stock, cannot approve.">
    <span>
    <Switch className={switchClass} disabled onClick={toggleApproval} loading={loading} checkedChildren='active' unCheckedChildren='approve' checked={false} />
    </span>
    </Tooltip>

    //for status approve and active we have toggle feature so we need switch
    //if (status === 'approve' || status === 'paid') return <Switch className={switchClass} onClick={toggleApproval} loading={loading} checkedChildren='paid' unCheckedChildren='approve' checked={defaultCheckApprove} />
    
    //for status approve 
    if (status === 'paid') {
        return (
            <Popconfirm
                title={
                    <>
                        Após aprovar o pedido, será gerada a nota fiscal, o código de rastreio,
                        <br />
                        e o pedido não poderá ser cancelado. Deseja continuar?
                    </>
                }
                onConfirm={toggleApproval} // Função para aprovar o pedido
                onCancel={() => setOpenToBeReturnedForm(false)} // Fecha o Popconfirm se cancelado
                okText="Aprovar"
                cancelText="Cancelar"
            >
                <Switch
                    className={switchClass}
                    onClick={() => setOpenToBeReturnedForm(true)} // Abre o Popconfirm ao clicar
                    loading={loading}
                    checkedChildren="Pago"
                    unCheckedChildren="Aprovar"
                    checked={false}
                />
            </Popconfirm>
        );
    }
    
    //for status approve 
    if (status === 'approve') {
        return <Popconfirm
            title={toBeDispatchForm}
            open={openToBeDispatchForm}
            onConfirm={switchToToBeDispatch}
            onCancel={()=>setOpenToBeDispatchForm(false)}
            okButtonProps={{ loading }}
            okText="Enviar Código"
            cancelText="Cancelar"
        >
            <Switch className={switchClass} onClick={()=>setOpenToBeDispatchForm(true)} loading={loading} checkedChildren='aprovado' unCheckedChildren='enviado' checked={false} />
        </Popconfirm>
    }

    //for status approve 
    if (status === 'dispatch') {
        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                 <Popconfirm
                    title={toBeReturnedForm}
                    open={openToBeReturnedForm}
                    onConfirm={switchToToBeReturned}
                    onCancel={() => setOpenToBeReturnedForm(false)}
                    okButtonProps={{ loading }}
                    okText="Devolvido"
                    cancelText="Cancelar"
                >
                    <Switch
                    className={switchClass}
                    onClick={() => setOpenToBeReturnedForm(true)}
                    loading={loading}
                    checkedChildren="Enviado"
                    unCheckedChildren="Devolvido"
                    checked={false}
                    />
                </Popconfirm> 
               <Popconfirm
                    title="O Pedido foi entregue?"
                    onConfirm={switchToComplete}
                    open={openToBeCompleteForm}
                    onCancel={() => setOpenToBeCompleteForm(false)} // Fecha o Popconfirm se cancelado
                    okText="Sim"
                    cancelText="Cancelar"
                >
                <Switch
                    className={switchClass}
                    onClick={() => setOpenToBeCompleteForm(true)} // Abre o Popconfirm ao clicar
                    loading={loading}
                    checkedChildren="Enviado"
                    unCheckedChildren="Entregue"
                    checked={false}
                />
                </Popconfirm> 
            </div>
        );          
    }

    //for status approve 
    if (status === 'tobereturned') {
        return <Popconfirm
            title={toBeReturnedForm}
            open={openToBeReturnedForm}
            onConfirm={switchToToBeReturned}
            onCancel={()=>setOpenToBeReturnedForm(false)}
            okButtonProps={{ loading }}
            okText="Submit"
            cancelText="Cancel"
        >
            <Switch className={switchClass} onClick={()=>setOpenToBeReturnedForm(true)} loading={loading} checkedChildren='Completo' unCheckedChildren='Devolvido' checked={defaultCheckComplete} />
        </Popconfirm>
    }

    //for status to be returned
    // if ( status === 'tobereturned') {
    //     return <Switch className={switchClass} onClick={switchToComplete} loading={loading} checkedChildren='complete' unCheckedChildren='toberetured' checked={false} />
    // }
    //for other status
    const otherStatus = status => {
        const statusLabels = {
            'active': 'A Pagar',
            'paid': 'Pago',
            'approve': 'Aprovado',
            'dispatch': 'Enviado',
            'cancel': 'Cancelado',
            'complete': 'Finalizado',
            'tobereturned': 'Para ser devolvido',
            'return': 'Devolvido',
            'undefined': 'Desconhecido',  // caso o status seja indefinido
        };
        
        let badgeClass = status === 'dispatch' ? "badge badge-pill badge-primary" :
                    status === 'cancel' ? "badge badge-pill badge-danger" :
                        status === 'complete' ? "badge badge-pill badge-success" :
                        status === 'active' ? "badge badge-pill badge-warning" :
                            status === 'tobereturned' ? "badge badge-pill badge-warning" :
                                "badge badge-pill badge-dark"
        return <span className={badgeClass}>{statusLabels[status]}</span>
    }
    return (
        <>
            {otherStatus(status)}
        </>
    )
}

OrderStatus.propTypes = {
    status: PropTypes.string,
    order_id: PropTypes.string,
    admin_id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    loading: PropTypes.bool.isRequired, 
}
const mapStateToProps = (state) => ({
    loading: state.order.orderStatusLoading
})

export default connect(mapStateToProps,{toggleOrderApproval, toggletobeReturnOrder, toggletobeDispatchOrder, toggletobeComplete})(React.memo(OrderStatus))
