import React, { useEffect, useState } from "react";
import {
    Button,
    Form,
    Input,
    Select,
    Col,
    Row
} from "antd";
import Categories from "./Categories";
import { useHistory } from 'react-router-dom';

const BasicInformation = ({ brands, layout, next, basicFormData }) => {
    const [form] = Form.useForm()
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [_brands, set_brands] = useState([]);
    const history = useHistory();

    useEffect(() => {
        set_brands(brands.map(b => ({
            label: b.brandName,
            value: b.slug
        })))
    }, [brands]);

    useEffect(() => {
        const { category } = basicFormData
        form.setFieldsValue({...basicFormData})
        setSelectedCategories([...selectedCategories, ...category])
    }, [basicFormData])

    const onFinish = (values) => {
        next()
    };

    const onFinishFailed = (errorInfo) => {
        // console.log("Failed:", errorInfo);
    };
    const onSubmit = () => {
        form.submit()
    }
    const onBack = () => {
        history.goBack();
    }
    const handleCategory = (e) => {
        let isAlreadyAdded = selectedCategories.includes(e.key);
        if (!isAlreadyAdded) {
            form.setFieldsValue({
                category: [...selectedCategories, e.key],
            })
        }
        return isAlreadyAdded
            ? null
            : setSelectedCategories([...selectedCategories, e.key]);
    };
    const handleDeselectCategory = (value) => {
        return setSelectedCategories(
            selectedCategories.filter((cat) => cat !== value)
        );
    };
    return (
        <>
            <Form
                {...layout}
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Form.Item
                    label="Nome do Produto"
                    name="name"
                    rules={[
                        {
                            type: 'string',
                            required: true,
                            message: "Por favor informe o nome do produto!",
                        },
                    ]}
                >
                    <Input style={{ width: '100%' }} placeholder="product name" />
                </Form.Item>
                <Categories
                    selectedCategories={selectedCategories}
                    handleClick={handleCategory}
                    handleDeselect={handleDeselectCategory}
                />                
                <Row justify="center" gutter={16}>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label="Modelo"
                            name="model"
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: "Por favor informe o modelo do produto!",
                                }
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="Modelo do produto" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={8}>
                        <Form.Item
                            label="Marca"
                            name="brand"
                            rules={[
                                {
                                    type: 'string',
                                    required: true,
                                    message: 'Por favor informe a marca do produto!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a brand"
                                optionFilterProp="children"
                                options={_brands}
                                filterOption={(input, option) =>
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div style={{padding: '8px 0'}}>
                            <Form.Item
                                label="Tags"
                                name="tags"
                                rules={[
                                    {
                                        type: 'array',
                                        required: true,
                                        message: 'Por favor informe a tag do produto!',
                                    }
                                ]}
                            >
                                <Select open={false} mode="tags" style={{ width: '100%' }} placeholder="Adicionar Tags" />
                            </Form.Item>
                        </div>
                    </Col>
                </Row>
            </Form>
            <div className="steps-action text-right">
                <Button style={{ margin: "0 8px" }} onClick={onBack}>
                 Voltar
                </Button>
                <Button type="primary" onClick={onSubmit}>
                    Avançar
                </Button>
            </div>
        </>

    );
};

export default React.memo(BasicInformation)