import React, {useEffect, useState, useMemo} from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from "prop-types";
import {
    Button,
    Form,
    Input,
    Select,
    Col,
    Row
} from "antd";
import ImageUploader from "./ImageUploader";
import { districts, productColors, productWarranty} from "../../../../utils/common";
import InputMask from 'react-input-mask';

const { Option } = Select;

const DetailInformation = ({ layout, next, prev, detailFormData }) => {
    const [form] = Form.useForm()
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    
    const _districts = useMemo(() => {
        let dts = districts.map(d=>({
            label:d,
            value:d
        }))
        return dts
    }, [districts])


    useEffect(() => {
        const {availableDistricts} = detailFormData
        form.setFieldsValue({ ...detailFormData })
        setSelectedDistricts([...selectedDistricts, ...availableDistricts])
    }, [detailFormData])


    const handleDeselectDistrict = (value) => {
        return setSelectedDistricts(
            selectedDistricts.filter((cat) => cat !== value)
        );
    };
    const onFinish = (values) => {
        next()
    };
    const onSubmit = () => {
        form.submit()
    }

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <>
            <Form
                {...layout}
                name="detail"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                
                <Col className="gutter-row" span={24}>
                    <Form.Item
                        label="Descrição"
                        name="description"
                        rules={[
                            {
                                type: 'string',
                                required: true,
                                message: "Por favor informe a descrição do produto!",
                            },
                        ]}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                },
                                placeholder:"Descrição completa do produto aqui..",
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                form.setFieldsValue({
                                    description: data,
                                })
                            }}
                            onReady={( editor) => {
                                let value = form.getFieldValue('description')
                                value && editor.setData(value)
                            }}
                        />
                    </Form.Item>
                </Col>

                <Col className="gutter-row" span={24}>
                    <Form.Item
                        label="Destaques"
                        name="highlights"
                        rules={[
                            {
                                type:'string',
                                required: true,
                                message: "Por favor informe os destaques do produto!",
                            },
                        ]}
                    >
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: {
                                    items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote'],
                                },
                                placeholder:'Descrição completa do produto aqui..'
                                }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                form.setFieldsValue({
                                    highlights: data,
                                })
                            }}
                            onReady={(editor) => {
                                let value = form.getFieldValue('highlights')
                                value && editor.setData(value)
                            }}
                        />
                    </Form.Item>
                </Col>

                <Row justify="center" gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Cor"
                            name="color"
                            rules={[
                                {
                                type: "array",
                                required: true,
                                message: "Por favor, selecione ao menos uma cor!",
                                },
                            ]}
                            >
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="Selecione uma ou mais cores"
                                allowClear
                            >
                                {productColors.map((color) => (
                                <Option key={color} value={color}>
                                    {color}
                                </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            label="Cor"
                            name="color"
                            rules={[
                                {
                                    type: 'array',
                                }
                            ]}
                        >
                            <Select open={false} mode="tags" style={{ width: '100%' }} placeholder="azul, " />
                        </Form.Item> */}
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Garantia"
                            name="warranty"
                            rules={[
                                {
                                required: true,
                                message: "Por favor informe a garantia do produto!",
                                },
                            ]}
                            >
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Informe a garantia do produto!"
                            >
                                {productWarranty.map((garantia) => (
                                <Option key={garantia} value={garantia}>
                                    {garantia}
                                </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        {/* <Form.Item
                            label="Garantia"
                            name="warranty"
                            rules={[
                                {
                                    type: 'string',
                                    // required: true,
                                    message: 'Por favor informe a garantia do produto!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item> */}
                    </Col>
                </Row>
                <Row justify="center" gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Peso em (kg)"
                            name="weight"
                            rules={[
                                { required: true, message: 'O Peso é obrigatório.' },
                                {
                                pattern: /^[0-9]{1,2},[0-9]{1}$/, // Validação para até 2 dígitos inteiros e 1 decimal
                                message: "Peso inválido. Exemplo: 2,3 ou 23,3.",
                                },
                            ]}
                        >
                            <Input placeholder="Digite o peso" />
                        </Form.Item>
                        {/* <Form.Item
                            label="Peso em (kg)"
                            name="weight"
                            rules={[{ required: true, message: 'O Peso é obrigatório.' }]}
                            >
                            <Input type="number" placeholder="Digite o peso" />
                        </Form.Item> */}
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Vídeo ID"
                            name="videoURL"
                            rules={[
                                {
                                    type: 'string',
                                }
                            ]}
                        >
                            <Input style={{ width: '100%' }} placeholder="KaAkyTd165Y" />
                        </Form.Item>
                    </Col>   
                </Row>

                <Row justify="center" gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Retorno"
                            name="return"
                            rules={[
                            {
                                required: true,
                                message: "Por favor, selecione uma opção de retorno!",
                            },
                            ]}
                        >
                            <Select placeholder="Selecione uma opção">
                                <Option value="Grátis">Grátis</Option>
                                <Option value="Pago">Pago</Option>
                            </Select>
                        </Form.Item> 
                        {/* <Form.Item
                            label="Retorno"
                            name="return"
                            rules={[
                                {
                                    type: 'string',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item> */}
                    </Col>
                    <Col className="gutter-row" span={12} gutter={6}>
                        <Form.Item
                            label="Dimensões:"
                            name="size"
                            rules={[
                            {
                                required: true,
                                message: "Por favor, insira as dimensões no formato 010x030x248.",
                            },
                            {
                                pattern: /^\d{3}x\d{3}x\d{3}$/,
                                message: "As dimensões devem estar no formato 010x030x248.",
                            },
                            ]}
                        >
                            <InputMask
                                mask="999x999x999"
                                className="ant-input"
                                placeholder="Ex: 010x030x248 cm"
                            >
                            {(inputProps) => <Input {...inputProps} />}
                            </InputMask>
                        </Form.Item>

                        {/* <Form.Item
                            label="Tamanho"
                            name="size"
                            rules={[
                                {
                                    type: 'array',
                                }
                            ]}
                        >
                            <Select open={false} mode="tags" style={{ width: '100%' }}/>
                        </Form.Item> */}
                    </Col>
                </Row>

                <Row justify="center" gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label="Images"
                            name="images"
                            rules={[
                                {
                                    type: 'array',
                                    required: true,
                                    message: 'Por favor adicione imagens do produto!',
                                }
                            ]}
                        >
                            <ImageUploader form={form} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item                    
                            label="Disponível em"
                            name="availableDistricts"
                            rules={[
                                {
                                    type: 'array',
                                    required: true,
                                    message: 'Forneça os estados disponíveis para envio do produto!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="Selecione os Estados"
                                optionFilterProp="children"
                                value={selectedDistricts}
                                onDeselect={handleDeselectDistrict}
                                options={_districts}
                                filterOption={(input, option) =>
                                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="steps-action text-right">
                <Button style={{ margin: "0 8px" }} onClick={()=>prev(form.getFieldsValue())}>
                    Voltar
                </Button>
                <Button type="primary" onClick={onSubmit}>
                    Avançar
                </Button>
            </div>
        </>

    );
};

DetailInformation.propTypes = {
    // uploadedImages: PropTypes.array,
    detailFormData: PropTypes.object,
};


export default React.memo(DetailInformation);