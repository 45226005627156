import React, { useState,useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {updateProfile} from '../../../redux/actions/profile_actions'

const ProfileForm = ({user, updateProfile, loading}) => {
    const [profile, setProfile] = useState({
        name:'',
        shopName:'',
        address:'',
        district:'',
        wardno:'',
        phone:'',
        muncipality:'',
        oldPassword:'',
        newPassword:'',
        holidayStart:'',
        holidayEnd:'',
    });
    useEffect(()=>{
        user && setProfile({
            ...profile,
            name:user?.name?user.name:'',
            shopName:user?.shopName?user.shopName:'',
            address:user?.address?user.address:'',
            district:user?.district?user.district:'',
            wardno:user?.wardno?user.wardno:'',
            phone:user?.phone?user.phone:'',
            muncipality:user?.muncipality?user.muncipality:'',
            holidayEnd: user?.holidayMode?.end ? user.holidayMode.end:'',
            holidayStart: user?.holidayMode?.start ? user.holidayMode.start:'',
        })
    },[user])
   const {name,address,muncipality,shopName,district,wardno,phone,holidayEnd,holidayStart,oldPassword,newPassword} = profile
   const onChange = e => setProfile({...profile,[e.target.name]:e.target.value})
    const onSubmit = async e => {
        e.preventDefault()
        updateProfile(profile,user._id)
        
    }
    return (
        <>
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Complete seu perfil</h5>
                    <h6 className="card-subtitle text-muted">Informações da conta do vendedor.</h6>
                </div>
                <div className="card-body">
                    <form onSubmit={e=> onSubmit(e)}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmail4">Nome do vendedor</label>
                                <input type="text" className="form-control" id="inputEmail4" placeholder="Nome"
                                name='name'
                                value={name}
                                onChange={onChange}
                                 />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputEmai4">Nome da loja</label>
                                <input type="text" className="form-control" id="inputEmai4" placeholder="Nome da loja"
                                    name='shopName'
                                    value={shopName}
                                    onChange={onChange} />
                            </div>
                            {/* <div className="form-group col-md-6">
                                <label htmlFor="dist">Estado</label>
                                <input type="text" className="form-control" id="dist" placeholder="Estado" 
                                    name='district'
                                    value={district}
                                    onChange={onChange}
                                />
                            </div>         
                            
                            <div className="form-group col-md-6">
                                <label htmlFor="inputAddress">Cidade</label>
                                <input type="text" className="form-control" id="inputAddress" placeholder="Cidade"
                                        name='muncipality'
                                        value={muncipality}
                                        onChange={onChange} />
                            </div> */}
                            {/* <div className="form-group col-md-6">
                                <label htmlFor="add">Endereço</label>
                                <input type="text" className="form-control" id="add" placeholder="Endereço"
                                    name='address'
                                    value={address}
                                    onChange={onChange} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputZip">Número</label>
                                <input type="number" className="form-control" id="inputZip" 
                                        name='wardno'
                                        value={wardno}
                                        onChange={onChange}
                                />
                            </div> */}
                            <div className="form-group col-md-6">
                                <label htmlFor="inputZi">Telefone</label>
                                <input type="text" className="form-control" id="inputZi"
                                    name='phone'
                                    value={phone}
                                    onChange={onChange}
                                />
                            </div>
                            {/* <div className="form-group col-md-3">
                                <label htmlFor="inputZ">Início do feriado</label>
                                <input type="number" className="form-control" id="inputZ"
                                    name='holidayStart'
                                    value={holidayStart}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="input">Fim do feriado</label>
                                <input type="number" className="form-control" id="input"
                                    name='holidayEnd'
                                    value={holidayEnd}
                                    onChange={onChange}
                                />
                            </div> */}
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPassword4">Senha</label>
                                <input type="password" className="form-control" id="inputPassword4" placeholder="Senha"
                                    name='oldPassword'
                                    value={oldPassword}
                                    onChange={onChange} />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPassword">Nova Senha</label>
                                <input type="password" className="form-control" id="inputPassword" placeholder="Nova Senha"
                                    name='newPassword'
                                    value={newPassword}
                                    onChange={onChange} />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={loading}>Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}

ProfileForm.propTypes = {
    user: PropTypes.object,
    updateProfile: PropTypes.func.isRequired,
    loading: PropTypes.bool,
}
const mapStateToProps = (state) => ({
    loading: state.auth.loading,
    user: state.auth.adminProfile,
})


export default connect(mapStateToProps,{updateProfile})(React.memo(ProfileForm))
